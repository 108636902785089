import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App/index.js';
import { ThemeProvider } from 'styled-components'
import { themeSettings } from './App/theme/theme-settings';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={themeSettings}>
    <App />
  </ThemeProvider>
);
