import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

body {
    background-color: ${props => props.theme.colors.bg()};
    color: ${props => props.theme.colors.white()};
    /*font-family: "Kanit", Arial, Helvetica, sans-serif;*/
}

.tw-text-primary {
    color: ${props => props.theme.colors.primary()};
}

.tw-bg-primary {
    background-color: ${props => props.theme.colors.primary()};
}

.tw-border-primary {
    border-color: ${props => props.theme.colors.primary()};
}

.tw-border-primary_0_0_30 {
    border-color: ${props => props.theme.colors.primary(0,0,0.3)};
}

.tw-bg-primary_-20_20 {
    background-color: ${props => props.theme.colors.primary(-20,20)};
}

.tw-text-themeBlack {
    color: ${props => props.theme.colors.black()};
}

.tw-bg-themeBlack {
    background-color: ${props => props.theme.colors.black()};
}

.tw-border-themeBlack {
    border-color: ${props => props.theme.colors.black()};
}

.tw-text-themeWhite {
    color: ${props => props.theme.colors.white()};
}

.tw-bg-themeWhite {
    background-color: ${props => props.theme.colors.white()};
}

.tw-border-themeWhite {
    border-color: ${props => props.theme.colors.white()};
}

.tw-text-error {
    color: ${props => props.theme.colors.error()};
}

.tw-bg-error {
    background-color: ${props => props.theme.colors.error()};
}

.tw-border-error {
    border-color: ${props => props.theme.colors.error()};
}

.tw-text-secondary {
    color: ${props => props.theme.colors.secondary()};
}

.tw-bg-secondary {
    background-color: ${props => props.theme.colors.secondary()};
}

.tw-border-secondary {
    border-color: ${props => props.theme.colors.secondary()};
}

.tw-outline-secondary {
    outline-color: ${props => props.theme.colors.secondary()};
}

.tw-bg-secondary_0_0_80 {
    background-color: ${props => props.theme.colors.secondary(0,0,0.8)};
}

.focus-tw-outline-secondary {
    &:focus {
        outline-color: ${props => props.theme.colors.secondary()};
    }
}

.tw-text-warning {
    color: ${props => props.theme.colors.warning()};
}

.hover-tw-text-warning {
    &:hover {
        color: ${props => props.theme.colors.warning()};
    }
}

.hover-tw-border-warning {
    &:hover {
        border-color: ${props => props.theme.colors.warning()};
    }
}

.tw-bg-warning {
    background-color: ${props => props.theme.colors.warning()};
}

.tw-border-warning {
    border-color: ${props => props.theme.colors.warning()};
}

.tw-text-bg {
    color: ${props => props.theme.colors.bg()};
}

.tw-bg-bg_0_0_90 {
    background-color: ${props => props.theme.colors.bg(0,0,0.9)};
}

.placeholder-text-gray-400 {
    &::placeholder {
        color: #9CA3AF;
    }
}

.bg-gray-300 {
    background-color: rgb(209 213 219);
}

.hover-tw-bg-warning {
    &:hover {
        background-color: ${props => props.theme.colors.warning()};
    }
}

.tw-group {
    &:hover {
        .group-hover-tw-text-warning {
            color: ${props => props.theme.colors.warning()};
        }
    }
}

.md-tw-bg-themeWhite {
    @media screen and (min-width: 768px) {
        background-color: ${props => props.theme.colors.white()};
    }
}

.md-tw-text-secondary {
    @media screen and (min-width: 768px) {
        color: ${props => props.theme.colors.secondary()};
    }
}

.md-tw-bg-themeWhite {
    @media screen and (min-width: 768px) {
        background-color: ${props => props.theme.colors.white()};
    }
}

.gap-1 {
    gap: 4px;
}

.gap-2 {
    gap: 8px;
}

.gap-3 {
    gap: 12px;
}

.gap-4 {
    gap: 16px;
}

.gap-8 {
    gap: 32px;
}

`

export default GlobalStyle