export const themeSettings = () => ({
    colors: {
        primary: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(30, ${Math.max(0,Math.min(100,62+hueOffset))}%, ${Math.max(0,Math.min(100,41+brightnessOffset))}%, ${opactiy})`,
        secondary: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(152, ${Math.max(0,Math.min(100,17+hueOffset))}%, ${Math.max(0,Math.min(100,46+brightnessOffset))}%, ${opactiy})`,
        warning: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(35, ${Math.max(0,Math.min(100,83+hueOffset))}%, ${Math.max(0,Math.min(100,57+brightnessOffset))}%, ${opactiy})`,
        bg: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(17, ${Math.max(0,Math.min(100,56+hueOffset))}%, ${Math.max(0,Math.min(100,16+brightnessOffset))}%, ${opactiy})`,
        black: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(240, ${Math.max(0,Math.min(100,22+hueOffset))}%, ${Math.max(0,Math.min(100,4+brightnessOffset))}%, ${opactiy})`,
        white: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(53, ${Math.max(0,Math.min(100,76+hueOffset))}%, ${Math.max(0,Math.min(100,94+brightnessOffset))}%, ${opactiy})`,
        error: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(11, ${Math.max(0,Math.min(100,100+hueOffset))}%, ${Math.max(0,Math.min(100,34+brightnessOffset))}%, ${opactiy})`,
        success: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(152, ${Math.max(0,Math.min(100,17+hueOffset))}%, ${Math.max(0,Math.min(100,46+brightnessOffset))}%, ${opactiy})`
    }
})