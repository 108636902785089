import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import GlobalStyle from './theme/global-styles';
import { SuspenseLoader } from './SuspenseLoader/index.js';
//import AdminRoute from '../util/privateRoutes/AdminRoute/index.js';
//import { MobileApp } from '../pages/MobileApp/index.js';
//import { Home } from '../pages/Home';
//import { MobileAppWrapper } from '../pages/MobileAppWrapper';
//import { AdminHome } from '../pages/AdminHome';

const AdminRoute = lazy(() => import('../util/privateRoutes/AdminRoute/index.js'));
const EmployeeRoute = lazy(() => import('../util/privateRoutes/EmployeeRoute/index.js'));

const Home = lazy(() => import('../pages/Home/index.js'));
const MobileAppWrapper = lazy(() => import('../pages/MobileAppWrapper/index.js'));
const AdminHome = lazy(() => import('../pages/AdminHome/index.js'));
const EmployeeHome = lazy(() => import('../pages/EmployeeHome/index.js'));
const AdminLogin = lazy(() => import('../pages/AdminLogin/index.js'));
const EmployeeLogin = lazy(() => import('../pages/EmployeeLogin/index.js'));
const MobileApp = lazy(() => import('../pages/MobileApp/index.js'));

export const App = () => {

    return (
        <BrowserRouter>

            <GlobalStyle />

            <Suspense fallback={<SuspenseLoader />}>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/app' element={<MobileApp />} />
                    <Route exact path='/app/test' element={<MobileAppWrapper><MobileApp /></MobileAppWrapper>} />
                    <Route exact path='/admin' element={<AdminRoute><AdminHome /></AdminRoute>} />
                    <Route exact path='/employee' element={<EmployeeRoute><EmployeeHome /></EmployeeRoute>} />
                    <Route exact path='/admin/login' element={<AdminLogin />} />
                    <Route exact path='/employee/login' element={<EmployeeLogin />} />
                    <Route exact path='/login' element={<AdminLogin />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}